import React, { useState } from "react";
import Icon from "@mui/material/Icon";
import MDButton from "../../components/MDButton";
import DeleteModal from "../../pages/merchant/merch-templates/[id]/DeleteModal";

function DeleteCell({ uri, onSuccess, children, dto }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <DeleteModal
        open={open}
        dto={dto}
        onClose={() => setOpen(false)}
        uri={uri}
        onSuccess={onSuccess}
      >
        {children}
      </DeleteModal>
      <MDButton variant="gradient" color="warning" iconOnly onClick={() => setOpen(true)}>
        <Icon>delete</Icon>
      </MDButton>
    </>
  );
}

export default DeleteCell;
