import React from "react";
import Select from "components/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";

const merchants = ["portal", "ubcomedy"];

function MerchantsField({ value, onChange }) {
  const handleChange = (e) => onChange?.(e.target.value);

  return (
    <>
      <InputLabel id="demo-multiple-checkbox-label">Merchants</InputLabel>
      <Select
        multiple
        value={value || []}
        id="demo-multiple-checkbox"
        labelId="demo-multiple-checkbox-label"
        input={<OutlinedInput label="Tag" />}
        onChange={handleChange}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 48 * 4.5 + 8,
              width: 250,
            },
          },
        }}
      >
        {merchants.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={(value || [])?.includes(name)} />
            <ListItemText primary={name?.toUpperCase()} />
          </MenuItem>
        ))}
      </Select>
    </>
  );
}

export default MerchantsField;
