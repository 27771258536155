import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import dayjs from "dayjs";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import { useMutation, useQuery } from "lib/hooks";
import Loader from "components/Loader";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import CheckBox from "components/CheckBox";
import MDButton from "components/MDButton";
import UserSelect from "../../events/[id]/UserSelect";

function NewValidator() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [selected, setSelected] = useState(null);
  const { data, loading } = useQuery({ uri: "/event/exclude-parent" });
  const events = data;
  const { request, loading: saving } = useMutation({ uri: "/validator/add/validator" });

  return (
    <MDBox>
      <MDBox mb={3}>
        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} md={6} xl={4}>
            <UserSelect value={user} onChange={setUser} />
          </Grid>
        </Grid>
        {loading ? (
          <Loader />
        ) : (
          <>
            {events?.map((event) => (
              <Card
                key={event._id}
                sx={{
                  gap: 5,
                  padding: "20px",
                  marginBottom: "20px",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                onClick={() => setSelected(selected === event._id ? null : event._id)}
              >
                <MDAvatar size="xl" src={event.cover_image_v} variant="rounded" />
                <div style={{ flex: "1" }}>
                  <MDTypography>{event.name}</MDTypography>
                  <MDTypography fontSize="14px" color="light">
                    {event?.location?.name}
                  </MDTypography>
                  <MDTypography fontSize="14px" color="light">
                    {dayjs(event.startDate).format("YYYY.MM.DD HH:mm")}
                  </MDTypography>
                </div>
                <CheckBox value={selected === event?._id} />
              </Card>
            ))}
            <MDBox display="flex" justifyContent="flex-end">
              <MDButton
                type="submit"
                variant="gradient"
                color="info"
                size="large"
                loading={saving}
                onClick={() => {
                  if (user?._id && selected) {
                    const dto = {
                      userId: user?._id,
                      eventId: selected,
                    };
                    return request(dto)
                      .then((res) => {
                        NotificationManager.success(res?.message || "Saved");
                        navigate("/validators");
                      })
                      .catch((er) => {
                        NotificationManager.warning(er?.message || "Error");
                      });
                  }
                  return NotificationManager.warning("Please select user and events");
                }}
              >
                Save
              </MDButton>
            </MDBox>
          </>
        )}
      </MDBox>
    </MDBox>
  );
}

export default NewValidator;
