import React, { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Form from "rc-field-form";
import { useMutation, useQuery } from "lib/hooks";
import { NotificationManager } from "react-notifications";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import FormItem from "components/FormItem";
import MDInput from "components/MDInput";
import MDDatePicker from "components/MDDatePicker";
import Loader from "components/Loader";
import ImageUpload from "components/ImageUpload";
import { MenuItem } from "@mui/material";
import RichText from "components/RichText";
import Select from "components/Select";
import CheckBox from "components/CheckBox";
import Collaboraters from "./Collaboraters";
import LatLon from "./LatLon";
import PaymentModal from "./PaymentModal";
import MerchantsField from "../../../components/MerchantsField";

const reduceSponsors = (arr) =>
  arr.reduce((acc, cur) => {
    if (cur?.role && cur?.userId?._id && !cur?._id) {
      acc.push({ ...cur, userId: cur?.userId?._id });
    }
    return acc;
  }, []);

function Index() {
  const { id } = useParams();
  const isNew = id === "new";
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const cloneId = searchParams.get("clone");

  const {
    data,
    loading: fetching,
    fetchData,
  } = useQuery({
    uri: `/event/${cloneId || id}`,
    manual: isNew && !cloneId,
  });
  const { request, loading: saving } = useMutation({
    uri: `/event${isNew ? "" : `/${id}`}`,
    method: isNew ? "post" : `put`,
  });
  const { request: upload, loading: uploading } = useMutation({ uri: "/s3-image-upload" });

  useEffect(() => {
    if (data?._id) {
      form.setFieldsValue({
        ...data,
        ...(cloneId ? { ticketTemplates: [], type: ["PUBLIC"] } : {}),
        isParent: (data?.type || []).includes("PARENT"),
        isFeatured: (data?.type || []).includes("FEATURED"),
      });
    }
  }, [fetching]);

  const isParentWatch = Form.useWatch("isParent", form);
  const seatInfo = Form.useWatch("seatInfo", form);
  const parentId = searchParams.get("parentId");
  const isSeatInfoRequired = !!Object.values(seatInfo || {}).filter((v) => !!v)?.length;

  return (
    <>
      {fetching ? (
        <Loader />
      ) : (
        <Form
          form={form}
          initialValues={
            {
              ...data,
              isFeatured: (data?.type || []).includes("FEATURED"),
              isParent: (data?.type || []).includes("PARENT"),
            } || {
              type: ["PUBLIC"],
            }
          }
          onFinish={({ sponsoredBy, collaboratedBy, isFeatured, type, isParent, ...rest }) => {
            const sponsors = reduceSponsors(sponsoredBy || []);
            const collaboraters = reduceSponsors(collaboratedBy || []);
            const typeDto = [type[0]];
            if (isFeatured && isFeatured !== "false") {
              typeDto.push("FEATURED");
            }
            if (isParent && isParent !== "false") {
              typeDto.push("PARENT");
            }

            request({
              ...rest,
              sponsors,
              ...(parentId ? { parentId } : {}),
              type: typeDto,
              sponsoredBy: sponsors,
              collaboratedBy: collaboraters,
            })
              .then((res) => {
                NotificationManager.success(res?.message || "Saved");
                navigate(res?.id ? `/events/${res?._id}` : "/events");
              })
              .catch((er) => NotificationManager.success(er?.message || er));
          }}
        >
          <div>
            <MDBox p={0}>
              <Grid container spacing={3} mb={3}>
                <Grid item xs={12} md={6} xl={4}>
                  <FormItem name={["merchants"]}>
                    <MerchantsField />
                  </FormItem>
                </Grid>
                <Grid item xs={12} md={6} xl={4} />
                <Grid item xs={12} md={6} xl={4} />
                <Grid item xs={12} md={6} xl={4}>
                  <FormItem name="name" rules={[{ required: true }]}>
                    <MDInput
                      fullWidth
                      label="Name"
                      variant="standard"
                      placeholder="Event name"
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormItem>
                </Grid>

                <Grid item xs={12} md={6}>
                  {!parentId && (
                    <FormItem name={["isParent"]}>
                      <CheckBox label="Is Parent" />
                    </FormItem>
                  )}
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                  <FormItem name="startDate" rules={[{ required: true }]}>
                    <MDDatePicker input={{ label: "Start date", name: "startDate" }} />
                  </FormItem>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                  <FormItem name="endDate" rules={[{ required: true }]}>
                    <MDDatePicker input={{ label: "End date", name: "endDate" }} />
                  </FormItem>
                </Grid>
                <Grid item xs={12} md={6} xl={4} />
                <Grid item xs={12} md={6} xl={4}>
                  <FormItem name={["type", 0]} rules={[{ required: true }]}>
                    <Select
                      fullWidth
                      variant="standard"
                      size="medium"
                      label="Type"
                      sx={{ minHeight: "29px" }}
                    >
                      <MenuItem value="PUBLIC">PUBLIC</MenuItem>
                      <MenuItem value="PRIVATE">PRIVATE</MenuItem>
                    </Select>
                  </FormItem>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                  <FormItem name={["isFeatured"]}>
                    <CheckBox label="Is Featured" />
                  </FormItem>
                </Grid>
              </Grid>
              <Grid container spacing={3} mb={3}>
                <Grid item xs={12} md={6} xl={4}>
                  <FormItem name="cover_image" rules={[{ required: true }]}>
                    <ImageUpload label="Cover" request={upload} loading={uploading} />
                  </FormItem>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                  <FormItem name="cover_image_v" rules={[{ required: true }]}>
                    <ImageUpload label="Cover mobile" request={upload} loading={uploading} />
                  </FormItem>
                </Grid>
              </Grid>
              <MDTypography variant="h5" mb={2}>
                Location
              </MDTypography>
              <Grid container spacing={3} mb={3}>
                <Grid item xs={12} md={6} xl={4}>
                  <FormItem name={["location", "name"]} rules={[{ required: true }]}>
                    <MDInput
                      label="Name"
                      fullWidth
                      variant="standard"
                      placeholder="UG ARENA, UB..."
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormItem>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                  <FormItem name={["location", "description"]} rules={[{ required: true }]}>
                    <MDInput
                      fullWidth
                      label="Description"
                      variant="standard"
                      placeholder="Location detail"
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormItem>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                  <FormItem name={["location", "link"]}>
                    <MDInput
                      fullWidth
                      variant="standard"
                      label="Google map link"
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormItem>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormItem name={["location", "hall_plan"]}>
                    <MDInput
                      fullWidth
                      variant="standard"
                      label="Hall plan url"
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormItem>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormItem name={["location", "hall_plan"]}>
                    <ImageUpload
                      isSvg
                      request={upload}
                      loading={uploading}
                      label="Hall plan import svg"
                    />
                  </FormItem>
                </Grid>

                <Grid item xs={12} md={6} xl={6}>
                  <FormItem name={["seatInfo", "name"]} rules={[{ required: isSeatInfoRequired }]}>
                    <MDInput
                      label="VIP name"
                      fullWidth
                      variant="standard"
                      placholder="VIP"
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormItem>
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                  <FormItem
                    name={["seatInfo", "name_en"]}
                    rules={[{ required: isSeatInfoRequired }]}
                  >
                    <MDInput
                      fullWidth
                      placholder="VIP"
                      label="VIP name en"
                      variant="standard"
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormItem>
                </Grid>

                <Grid item xs={12} md={6} xl={6}>
                  <FormItem
                    name={["seatInfo", "description"]}
                    rules={[{ required: isSeatInfoRequired }]}
                  >
                    <RichText label="VIP description" />
                  </FormItem>
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                  <FormItem
                    name={["seatInfo", "description_en"]}
                    rules={[{ required: isSeatInfoRequired }]}
                  >
                    <RichText label="VIP description en" />
                  </FormItem>
                </Grid>
                <Grid item xs={12}>
                  <FormItem name="location">
                    <LatLon />
                  </FormItem>
                </Grid>
              </Grid>
              <>
                <MDTypography variant="h5" mb={2}>
                  Duration
                </MDTypography>
                <Grid container spacing={3} mb={3}>
                  <Grid item xs={12} md={6} xl={4}>
                    <FormItem name={["duration", "day"]} rules={[{ required: true }]}>
                      <MDInput
                        label="Day"
                        fullWidth
                        type="number"
                        variant="standard"
                        placholder="0"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormItem>
                  </Grid>
                  <Grid item xs={12} md={6} xl={4}>
                    <FormItem name={["duration", "hour"]} rules={[{ required: true }]}>
                      <MDInput
                        fullWidth
                        label="Hour"
                        type="number"
                        variant="standard"
                        placholder="0"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormItem>
                  </Grid>
                  <Grid item xs={12} md={6} xl={4}>
                    <FormItem name={["duration", "min"]}>
                      <MDInput
                        fullWidth
                        type="number"
                        placholder="0"
                        label="Minute"
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormItem>
                  </Grid>
                </Grid>

                <MDBox mb={3}>
                  <FormItem name="description" rules={[{ required: true }]}>
                    <RichText label="Description" />
                  </FormItem>
                </MDBox>
                <MDBox mb={3}>
                  <FormItem name="description_en" rules={[{ required: true }]}>
                    <RichText label="Description en" />
                  </FormItem>
                </MDBox>
                <Grid container spacing={3} mb={3}>
                  <Grid item xs={12} md={6}>
                    <Card>
                      <MDBox pt={2} px={2}>
                        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                          Collaborators
                        </MDTypography>
                      </MDBox>
                      <MDBox p={2}>
                        <FormItem name="collaboratedBy">
                          <Collaboraters prefix="collaboratedBy" form={form} />
                        </FormItem>
                      </MDBox>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card>
                      <MDBox pt={2} px={2}>
                        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                          Sponsors
                        </MDTypography>
                      </MDBox>
                      <MDBox p={2}>
                        <FormItem name="sponsoredBy">
                          <Collaboraters prefix="sponsoredBy" form={form} />
                        </FormItem>
                      </MDBox>
                    </Card>
                  </Grid>
                  {!isParentWatch && (
                    <Grid item xs={12} md={6}>
                      <Card>
                        <MDBox pt={2} px={2}>
                          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                            Acceptable payment methods
                          </MDTypography>
                        </MDBox>
                        <MDBox p={2}>
                          <PaymentModal
                            id={id}
                            refetch={fetchData}
                            acceptablePaymentMethods={data?.acceptablePaymentMethods || []}
                          />
                        </MDBox>
                      </Card>
                    </Grid>
                  )}
                </Grid>
              </>
              <MDBox display="flex" justifyContent="flex-end" gap={4}>
                <MDButton
                  type="submit"
                  variant="gradient"
                  color="info"
                  size="large"
                  disabled={uploading}
                  loading={saving}
                >
                  Save
                </MDButton>
              </MDBox>
            </MDBox>
          </div>
        </Form>
      )}
    </>
  );
}

export default Index;
