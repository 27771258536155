import React, { useEffect, useState } from "react";
import Form from "rc-field-form";
import { NotificationManager } from "react-notifications";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { InputLabel, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import FormItem from "components/FormItem";
import MDInput from "components/MDInput";
import MDDatePicker from "components/MDDatePicker";
import Select from "components/Select";
import RichText from "components/RichText";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useMutation } from "lib/hooks";
import EventAutocomplete from "pages/ticket-templates/EventAutocomplete";
import Artists from "./Artists";
import Images from "./Images";
import DeleteModal from "./DeleteModal";
import MerchantsField from "../../../../components/MerchantsField";

function TemplateForm({ data, fetching, isNew, id }) {
  const [openRemove, setOpenRemove] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { request, loading: saving } = useMutation({
    uri: `/event/eventId/merchTemplate/${isNew ? "" : `/${id}`}`,
    method: isNew ? "post" : `put`,
  });

  const { request: putAttr, loading } = useMutation({
    uri: `/merch-template/attr`,
    method: `put`,
  });

  const { request: upload, loading: uploading } = useMutation({ uri: "/s3-image-upload" });

  useEffect(() => {
    if (data?._id) {
      form.setFieldsValue(data);
    }
  }, [fetching]);

  return (
    <>
      <DeleteModal
        uri={`/merch-template/${id}`}
        onSuccess={() => {
          navigate("/merchant/templates");
        }}
        open={openRemove}
        onClose={() => setOpenRemove(false)}
      />
      <Form
        form={form}
        initialValues={data}
        onFinish={({ artists, eventId, ...rest }) => {
          const sponsors = (artists || []).map((a) => a?._id);
          request(
            {
              ...rest,
              artists: sponsors,
            },
            isNew ? `/event/${eventId?._id}/merch-template` : `/merch-template/${id}`
          )
            .then((res) => {
              if (isNew && res?._id) {
                putAttr({}, `/merch-template/${res._id}/attribute`).then((resp) => {
                  if (resp.code) {
                    NotificationManager.success(res?.message || "Saved");
                    navigate(`/merchant/templates/${res?._id}`);
                  }
                });
              } else {
                NotificationManager.success(res?.message || "Saved");
              }
            })
            .catch((er) => NotificationManager.success(er?.message || er));
        }}
      >
        <Card>
          <MDBox p={3}>
            <Grid container spacing={3} mb={3} md={8}>
              <Grid item xs={12} md={6}>
                <FormItem name={["merchants"]}>
                  <MerchantsField />
                </FormItem>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormItem name={["eventId"]} rules={[{ required: true }]}>
                  <EventAutocomplete disabled={!!data?._id} />
                </FormItem>
              </Grid>
            </Grid>
            <Grid container spacing={3} mb={3}>
              <Grid item xs={12} md={6} xl={4}>
                <FormItem name="name" rules={[{ required: true }]}>
                  <MDInput
                    fullWidth
                    label="Name"
                    variant="standard"
                    placeholder="Merchant name"
                    InputLabelProps={{ shrink: true }}
                  />
                </FormItem>
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <FormItem name="name_en" rules={[{ required: true }]}>
                  <MDInput
                    fullWidth
                    label="Name en"
                    variant="standard"
                    placeholder="Merchant name en"
                    InputLabelProps={{ shrink: true }}
                  />
                </FormItem>
              </Grid>
            </Grid>
            <Grid container spacing={3} mb={3}>
              <Grid item xs={12} md={6} xl={4}>
                <FormItem name="startDate" rules={[{ required: true }]}>
                  <MDDatePicker input={{ label: "Start date", name: "startDate" }} />
                </FormItem>
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <FormItem name="endDate" rules={[{ required: true }]}>
                  <MDDatePicker input={{ label: "End date", name: "endDate" }} />
                </FormItem>
              </Grid>
            </Grid>

            <Grid container spacing={3} mb={3}>
              <Grid item xs={12} md={6} xl={4}>
                <FormItem name={["type"]} rules={[{ required: true }]}>
                  <Select
                    fullWidth
                    variant="standard"
                    size="medium"
                    label="Type"
                    sx={{ minHeight: "29px" }}
                  >
                    <MenuItem value="LIMITED">LIMTIED</MenuItem>
                    <MenuItem value="OPEN">SINGLE</MenuItem>
                  </Select>
                </FormItem>
              </Grid>
            </Grid>
            <InputLabel sx={{ marginBottom: "12px" }}>Images</InputLabel>
            <FormItem name="imageUrl">
              <Images loading={uploading} request={upload} />
            </FormItem>
            <MDBox mb={3}>
              <FormItem name="description" rules={[{ required: true }]}>
                <RichText label="Description" />
              </FormItem>
            </MDBox>
            <MDBox mb={3}>
              <FormItem name="description_en" rules={[{ required: true }]}>
                <RichText label="Description en" />
              </FormItem>
            </MDBox>
            <Grid container spacing={3} mb={3}>
              <Grid item xs={12} md={6}>
                <Card>
                  <MDBox pt={2} px={2}>
                    <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      Artists
                    </MDTypography>
                  </MDBox>
                  <MDBox p={2}>
                    <FormItem name="artists">
                      <Artists prefix="artists" form={form} />
                    </FormItem>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
            <MDBox display="flex" justifyContent="flex-end" gap={4}>
              <MDButton
                variant="gradient"
                color="warning"
                size="large"
                disabled={uploading || saving || loading}
                onClick={() => setOpenRemove(true)}
              >
                DELETE
              </MDButton>
              <MDButton
                type="submit"
                variant="gradient"
                color="info"
                size="large"
                loading={saving || loading}
                disabled={uploading || saving || loading}
              >
                Save
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </Form>
    </>
  );
}

export default TemplateForm;
